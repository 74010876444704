import "./App.css";
import LayoutwithSidebar from "./components/Layout";
import Login from "./components/Login";
import InternalLogin from "./components/internal-login";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Amplify from "aws-amplify";
import { useDispatch } from "react-redux";
import InternalHome from "./components/internal";
import { useCurrentLoginType } from "./provider/currentLoginTypeProvider";

function PrivateOutlet({ isLoggedIn }) {
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}
function PrivateOutletInternal({ isLoggedIn }) {
  return isLoggedIn ? <Outlet /> : <Navigate to="/internal-login" />;
}

const NotFoundPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

function App() {
  const { isLoggedIn } = useSelector((state) => state.logger);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentLoginType] = useCurrentLoginType();
  var dispatch = useDispatch();
  const location = useLocation();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (res) => {
        dispatch({
          type: "IS_LOGGEDIN",
          payload: true,
        });
        setLoading(false);
      })
      .catch((err) => {
        dispatch({
          type: "IS_LOGGEDIN",
          payload: false,
        });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    //dev
    Amplify.configure({
      Auth: {
        region: "ap-south-1",
        userPoolId:
          location.pathname === "/login"
            ? "ap-south-1_3oTzsWjgc"
            : "ap-south-1_V60ffBC2K",
        userPoolWebClientId:
          location.pathname === "/login"
            ? "4m7f4hn4lcjggv82l22h7stvlq"
            : "2e4ianvq8foiubuisms3g5bcci",
        //identityPoolId: "ap-south-1:14bcc5e7-5156-4a50-90bd-3ea995d90c68",
      },
      Analytics: {
        disabled: true,
      },
      aws_appsync_graphqlEndpoint:
        "https://ze6c42zp6fhz7oc4ydwsd7yine.appsync-api.ap-south-1.amazonaws.com/graphql",
      aws_appsync_region: "ap-south-1",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    });

    //prod
    // Amplify.configure({
    //   Auth: {
    //     region: "ap-south-1",
    //     userPoolId:
    //       location.pathname === "/internal-login" ||
    //       currentLoginType === "internal"
    //         ? "ap-south-1_Khw3RPRAC"
    //         : "ap-south-1_IFyLhICqh",
    //     userPoolWebClientId:
    //       location.pathname === "/internal-login" ||
    //       currentLoginType === "internal"
    //         ? "40sork452ou0mbpmvoofts4ntn"
    //         : "6p2b093b6r0bsn0df48g8kc2ks",
    //     identityPoolId: "ap-south-1:14bcc5e7-5156-4a50-90bd-3ea995d90c68",
    //     //mandatorySignIn: false,
    //     // ...(location.pathname === "/login" && {
    //     //   cookieStorage: {
    //     //     domain: "ghoshak.store", //localhost or .<domain>.com
    //     //     path: "/",
    //     //     expires: 365,
    //     //     sameSite: "none",
    //     //     secure: true,
    //     //   },
    //     // }),
    //   },
    //   aws_appsync_graphqlEndpoint:
    //     "https://zfcluediujcldm7ave3xpqaoja.appsync-api.ap-south-1.amazonaws.com/graphql",
    //   aws_appsync_region: "ap-south-1",
    //   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    // });
  }, [location.pathname]);

  if (process.env.NODE_ENV === "production") {
    window.console.log = () => {};
    window.console.warn = () => {};
  }

  const isMobile = width <= 768;

  if (isMobile) {
    return (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          height: "100dvh",
          textAlign: "center",
          fontSize: "1rem",
        }}
      >
        Please
        <a href="https://play.google.com/store/apps/details?id=com.ghoshak_owner&hl=en&gl=US&pli=1">
          download app
        </a>
        to access website builder in mobile
      </div>
    );
  }

  if (loading) {
    <div>Loading...</div>;
  }

  return (
    <>
      {!loading && (
        <Routes>
          {currentLoginType === "internal" ? (
            <Route
              path="/"
              element={<PrivateOutletInternal isLoggedIn={isLoggedIn} />}
            >
              <Route path="/" element={<LayoutwithSidebar />} />
              <Route path="/internal" element={<InternalHome />} />
            </Route>
          ) : (
            <Route path="/" element={<PrivateOutlet isLoggedIn={isLoggedIn} />}>
              <Route path="/" element={<LayoutwithSidebar />} />
            </Route>
          )}

          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/internal-login"
            element={
              isLoggedIn ? <Navigate to="/internal" /> : <InternalLogin />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;

// <Routes>

//       {/* <Route element={<PrivateOutlet />}>
//         <Route exact path="/" element={<LayoutwithSidebar />} />
//       </Route> */}
//       {/* <Route exact path="*" element={<div>404 Page is Not Founded</div>} /> */}
//     </Routes>
