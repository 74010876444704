import {
  useState,
  useEffect,
  useRef,
  useCallback,
  startTransition,
} from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Layout,
  Menu,
  message,
  Modal,
  Select,
  Space,
  Switch,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HomeOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { API, Auth } from "aws-amplify";
import { getShopDetails } from "../graphql/query";
import { TbTemplate } from "react-icons/tb";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import Joyride from "react-joyride";
import ActionButton from "./Buttons/button";
import { useDispatch } from "react-redux";
import { INITIAL_VALUES } from "../redux/form/formTypes";
import { useSelector } from "react-redux";
import DynamicModal from "./forms";
import { IoLaptopOutline } from "react-icons/io5";
import { ImMobile } from "react-icons/im";
import { IoIosTabletPortrait } from "react-icons/io";
import { HiColorSwatch } from "react-icons/hi";
import { MdDragIndicator } from "react-icons/md";
import { BiArrowFromLeft, BiMinus, BiPlus } from "react-icons/bi";
import { FaClosedCaptioning, FaFont } from "react-icons/fa";
import {
  SET_TEMPLATE_ORDER,
  UPDATE_CUSTOM_PALETTE,
  UPDATE_PALETTE,
  UPDATE_POST_MESSAGE,
  UPDATE_CLONE_POST_MESSAGE,
  UPDATE_SECTION_SETTINGS,
  UPDATE_TEMPLATE_ACTIVE,
  SET_TO_INITIAL,
  UPDATE_FONT_SETTINGS,
  UPDATE_ANIMATION_SETTINGS,
} from "../redux/postMessage/postMessageType";
import useOnClickOutside from "../hooks/useOutsideClick";
import { useBeforeunload } from "react-beforeunload";
import { UPDATE_TEMPLATE } from "../redux/postMessage/postMessageType";
import { GrClose } from "react-icons/gr";
import { editWebsiteTemplate } from "../graphql/mutation";
import Loader from "./loader";
import { RiListSettingsFill, RiLogoutCircleRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import previewImg from "../templates.json";
import { useCurrentLoginType } from "../provider/currentLoginTypeProvider";
import { useCurrentShopId } from "../provider/currentShopIdProvider";
import IframeComm from "./iFrameComp";
import { uniqueId } from "lodash";
import { FaExternalLinkAlt } from "react-icons/fa";

const { Title } = Typography;
const { Header, Sider } = Layout;

const useStyle = createUseStyles({
  selectStyles: {
    "& ul": {
      "& li": {
        "&:last-child": {
          marginBottom: "100px",
        },
      },
    },
  },
  menu: {
    "& .ant-menu-item-group-list": {
      width: "100%",
      height: "100%",
      "& .ant-menu-item ant-menu-item-selected ant-menu-item-only-child": {
        width: "100%",
        height: "100%",
        padding: "0px",
      },
    },
    "&.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title":
      {
        width: "100% !important",
        height: "100% !important",
        padding: "0px !important",
      },
    "& .ant-menu-sub.ant-menu-inline": {
      height: "400px",
      width: "100%",
      overflowY: "auto",
    },
  },
  themeMenu: {
    "&.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item":
      {
        height: "120px !important",
      },
  },
});

const LayoutwithSidebar = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const [isThemeHidden, setIsThemeHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // const [active, setActive] = useState("one");
  const [preview, setPreview] = useState("90%");
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const [editTemplate, setEditTemplate] = useState("");
  const postMessageData = useSelector((state) => state.postMessage.postMessage);
  const dynamicModalRef = useRef();
  const [isTemplateHide, setIsTemplateHide] = useState(true);
  const [isWebsiteSettingHidden, setIsWebsiteSettingHidden] = useState(true);
  const [isFontSettingsHidden, setIsFontSettingsHidden] = useState(true);
  const fontSettingRef = useRef();
  const selectDesignRef = useRef();
  const themeHiddenRef = useRef();
  const websiteSettingsHiddenRef = useRef();
  const order =
    postMessageData.pageProps.order?.filter((i) => i !== "topNotification") ||
    [];
  const [currentLoginType] = useCurrentLoginType();
  const [currentShopId, setCurrentShopId] = useCurrentShopId();
  const previousPostMessage = useSelector(
    (state) => state.postMessage.clonePostMessage
  );
  const [iframeKey, setIframeKey] = useState(uniqueId());
  const [googleFontList, setGoogleFontList] = useState([]);
  const defaultPostMessage = useSelector(
    (state) => state.postMessage.defaultPostMessage
  );
  const [scrollToView, setScrollToView] = useState("");
  const [shopDetails, setShopDetails] = useState({});
  const colorTheme = postMessageData.pageProps?.colorTheme
    ? postMessageData.pageProps?.colorTheme
    : defaultPostMessage.pageProps?.colorTheme;
  const [colorThemeValues, setColorThemeValues] = useState({
    backgroundColor: colorTheme?.backgroundColor,
    primaryColor: colorTheme?.primaryColor,
    accentColor1: colorTheme?.accentColor1,
    actionColor: colorTheme?.actionColor,
  });
  const [previewLoader, setPreviewLoader] = useState(false);
  useOnClickOutside(dynamicModalRef, () => {
    setEditTemplate("");
    setScrollToView("");
  });
  useOnClickOutside(selectDesignRef, () => {
    setIsTemplateHide(true);
    setScrollToView("");
  });
  useOnClickOutside(websiteSettingsHiddenRef, () => {
    setIsWebsiteSettingHidden(true);
    setScrollToView("");
  });
  useOnClickOutside(themeHiddenRef, () => setIsThemeHidden(true));
  const [isSignModal, setIsSignModal] = useState(false);

  function getItem(label, key, icon, children, type, id, style) {
    return {
      key,
      icon,
      children,
      label,
      type,
      id,
      style,
    };
  }

  const menuItem = (template, selectedTemplateId) => {
    const items = [
      getItem(
        <div
          onClick={() => {
            run && setStepIndex(3);
          }}
        >
          {templatesName[template]}
        </div>,
        template,
        <MdDragIndicator
          style={{
            cursor: "grab",
          }}
        />,
        previewImg[template]?.map((item, index) => {
          return getItem(
            item.imgName,
            item.id,
            null,
            [
              getItem(
                <img
                  onClick={() => handleSelectDesign(template, item.id)}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  title={item.imgName}
                  src={item.img}
                  alt={item.imgName}
                />,
                item.id
              ),
            ],
            "group",
            null,
            {
              backgroundColor: selectedTemplateId === item.id ? "#E5F7FF" : "",
              border: selectedTemplateId === item.id ? "5px solid #E5F7FF" : "",
            }
          );
        }),
        null,
        "menu-group"
      ),
    ];
    return items;
  };

  const templatesName = {
    aboutUs: "Welcome Page",
    about: "About Us",
    topNotification: "Top Notification",
    ourServices: "Our Services",
    ourProjects: "Our Projects",
    ourClients: "Our Client",
    ourBrands: "Our Brands",
    ourGallery: "Gallery",
    clientTestimonials: "Testimonials",
    videoGallery: "Videos",
    socialMedia: "Social Media",
    externalLink: "External Links",
    onlineProduct: "Our Products",
    appStore: "App Store",
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging && "lightgreen",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
  });

  const handleSelectDesign = (templateName, templateId) => {
    if (run) {
      setStepIndex(4);
    }
  };

  const handleClick = (template, event) => {
    if (event.key === "onlineProduct") {
      return;
    }
    if (event.key === "externalLink") {
      dispatch({
        type: UPDATE_TEMPLATE,
        payload: {
          key: template,
          templateName: "Classic Right",
          templateId: "donation_001",
        },
      });
      return;
    }
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: {
        key: template,
        templateName: "Classic Right",
        templateId: event.key,
      },
    });
  };

  const handleWebsitePreview = () => {
    setPreview("90%");
  };

  const handleMobilePreview = () => {
    setPreview("375px");
  };

  const handleTabletPreview = () => {
    setPreview("768px");
  };
  const getUserDetails = useCallback(() => {
    setPreviewLoader(true);
    API.graphql({
      query: getShopDetails,
      variables: { shopId: "e858c3cf-94a9-40f4-b4be-c554ca3a4b45" },
    })
      .then((res) => {
        const shopDetails = res.data.getShopDetails;
        console.log("shopDetail222s", shopDetails);
        if (shopDetails) {
          let websiteTemplate = JSON.parse(shopDetails.websiteTemplate);
          setShopDetails(shopDetails);
          if (!websiteTemplate["colorTheme"]) {
            websiteTemplate["colorTheme"] =
              defaultPostMessage.pageProps["colorTheme"];
          }
          websiteTemplate["colorPallet"] =
            defaultPostMessage.pageProps["colorPallet"];
          websiteTemplate["videoGallery"] = {
            ...websiteTemplate["videoGallery"],
            pageProps: {
              ...websiteTemplate?.["videoGallery"]?.["pageProps"],
              cardData:
                websiteTemplate?.["videoGallery"]?.["pageProps"]?.cardData?.map(
                  (i) => {
                    let tempVideoUrl = i.videoUrl;
                    return {
                      ...i,
                      videoUrl: "",
                      tempVideoUrl: tempVideoUrl,
                    };
                  }
                ) || [],
            },
          };
          const payload = {
            shopDetails: {
              shopName: shopDetails.shopName,
              shopAddress: shopDetails.shopAddress,
              shopId: shopDetails.userId,
              shopType: shopDetails.shopType,
              websiteTemplate: websiteTemplate,
              websiteContactPhoneNumber: shopDetails.websiteContactPhoneNumber
                ? shopDetails.websiteContactPhoneNumber
                : shopDetails.userPhoneNumber,
              shopEmail: shopDetails.userEmail,
              description: shopDetails.shopDescription,
              facebookLink: shopDetails.facebookLink,
              instagramLink: shopDetails.instagramLink,
              websiteStatus: shopDetails.websiteStatus,
              websiteUrl: shopDetails.websiteUrl,
              customDomainUrl: shopDetails.customDomainUrl,
              shopTimings: shopDetails.shopTimings,
            },
            pageProps: websiteTemplate,
          };
          dispatch({
            type: UPDATE_POST_MESSAGE,
            payload,
          });
          dispatch({
            type: UPDATE_CLONE_POST_MESSAGE,
            payload,
          });
          setIsLoading(false);
        }
        setPreviewLoader(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error11", err);
        setPreviewLoader(false);
        setIsLoading(false);
      });
  }, [dispatch, defaultPostMessage.pageProps]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFontSettingsHidden === false) {
      (async () => {
        try {
          const res = await fetch(
            "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyD_7iFIc6K5sk9IQR5TKx76Nbq72J_lnyk"
          );
          const data = await res.json();
          console.log("setGoogleFontList", data);
          setGoogleFontList(data.items);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isFontSettingsHidden]);

  useEffect(() => {
    setColorThemeValues({
      backgroundColor: colorTheme?.backgroundColor,
      primaryColor: colorTheme?.primaryColor,
      accentColor1: colorTheme?.accentColor1,
      actionColor: colorTheme?.actionColor,
    });
  }, [colorTheme]);

  const objectCompare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useBeforeunload((event) => {
    if (currentLoginType !== "internal") {
      const hasLoad = objectCompare(postMessageData, previousPostMessage);
      if (!hasLoad) {
        event.preventDefault();
      }
    }
  });

  useEffect(() => {
    if (run) {
      setRun(true);
      setSteps([
        {
          content: <div>Click the Section to edit the content!</div>,
          disableBeacon: true,
          placement: "bottom",
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: "iframe",
          title: "Edit the section content",
        },
        {
          content: <div>Update the section content</div>,
          disableBeacon: true,
          placement: "left",
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: "#container .formContainer",
          title: "Edit the section content",
        },
        {
          content: "Click the submit button to update the content",
          disableBeacon: true,
          disableOverlayClose: true,
          placement: "right",
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          spotlightPadding: 0,
          target: ".formContainer #submitBtn",
          title: "Edit the section content",
        },
        {
          content: <h2>Done!</h2>,
          placement: "center",
          target: "body",
        },
      ]);
    }
  }, [run]);

  const attributes = {
    // src: "http://192.168.0.106:3000",
    // shopDetails.customDomainUrl
    //   ? `${shopDetails.customDomainUrl.replace("http://", "https://")}/livedemo`
    //   : "https://ghoshakdemo.in/livedemo",
    src: "https://hellowords.in/livedemo",
    width: "100%",
    height: "100%",
    id: "iframe",
    style: {
      cursor: "pointer",
    },
  };

  console.log("defaultPostMessage", defaultPostMessage);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeContent: "center",
        }}
      >
        <Loader />
      </div>
    );
  }
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (run) {
      setStepIndex(2);
    }

    const items = reorder(order, result.source.index, result.destination.index);
    dispatch({
      type: SET_TEMPLATE_ORDER,
      payload: items,
    });
  };

  const handleClickEnable = (v) => {
    // setWebsiteTemplate((prev) => ({
    //   ...prev,
    //   [v.key]: {
    //     ...prev[v.key],
    //     active: true,
    //   },
    // }));
  };

  const onReceiveMessage = (v) => {
    const { data } = v;
    console.log("onReceiveMessage", data);
    if (data.template === "header") {
      setEditTemplate("header");
      return;
    }
    if (Object.keys(data).includes("active") || data.page === "ourContacts") {
      setEditTemplate(data.page);
      dispatch({
        type: INITIAL_VALUES,
        payload: data,
      });
    }
    if (run) {
      setStepIndex(1);
    }
  };

  function iframeZoom(type) {
    const iframe = document.getElementById("iframe");
    iframe.contentWindow.postMessage(
      {
        type,
      },
      "*"
    );
  }

  const handleSaveClick = async () => {
    let cloneWebsiteTemplate = structuredClone(postMessageData.pageProps);
    cloneWebsiteTemplate = {
      ...cloneWebsiteTemplate,
      videoGallery: {
        ...cloneWebsiteTemplate?.["videoGallery"],
        pageProps: {
          ...cloneWebsiteTemplate?.["videoGallery"]?.["pageProps"],
          cardData:
            cloneWebsiteTemplate?.["videoGallery"]?.[
              "pageProps"
            ]?.cardData?.map((i) => {
              let tempVideoUrl = i.tempVideoUrl;
              return {
                ...i,
                videoUrl: tempVideoUrl,
              };
            }) || [],
        },
      },
    };
    await API.graphql({
      query: editWebsiteTemplate,
      variables: {
        websiteTemplate: JSON.stringify(cloneWebsiteTemplate),
        shopId: currentLoginType === "internal" ? currentShopId : null,
      },
    })
      .then((data) => {
        getUserDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleScrollToView = (id) => {
    setScrollToView(id);
  };

  const handleOtherTemplate = (templateName) => {
    let data = postMessageData.pageProps[templateName]
      ? { ...postMessageData.pageProps[templateName] }
      : { ...defaultPostMessage.pageProps[templateName] };

    if (templateName === "topNotification") {
      data = {
        ...data,
        active: true,
        pageProps: {
          ...data?.pageProps,
          cardData: data?.pageProps?.cardData.map((item) => {
            return {
              ...item,
              showTimer: false,
            };
          }),
        },
      };
    } else {
      data = {
        ...data,
        active: true,
      };
    }
    dispatch({
      type: UPDATE_TEMPLATE_ACTIVE,
      payload: {
        key: templateName,
        content: data,
      },
    });
  };

  const otherTemplates = () => {
    const templates = {
      aboutUs: "Welcome Page",
      about: "About Us",
      topNotification: "Top Notification",
      ourServices: "Our Services",
      ourProjects: "Our Projects",
      ourClients: "Our Client",
      ourBrands: "Our Brands",
      ourGallery: "Gallery",
      clientTestimonials: "Testimonials",
      videoGallery: "Videos",
      socialMedia: "Social Media",
      externalLink: "External Links",
    };
    const keys = Object.keys(postMessageData.pageProps);
    keys.forEach((key) => {
      if (postMessageData.pageProps[key].active) {
        delete templates[key];
      }
    });
    console.log("templates", templates);
    return templates;
  };

  console.log("postMessageData", postMessageData);

  const handleThemeClick = (v) => {
    console.log("key", v.key);
    dispatch({
      type: UPDATE_PALETTE,
      payload: v.key,
    });
  };

  const handleColorChange = (e) => {
    setColorThemeValues((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    dispatch({
      type: UPDATE_CUSTOM_PALETTE,
      payload: {
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSignOut = () => {
    if (currentLoginType === "internal") {
      navigate("/internal");
      setCurrentShopId("");
      dispatch({
        type: SET_TO_INITIAL,
        payload: {},
      });
    } else {
      Auth.signOut()
        .then((res) => {
          dispatch({
            type: "IS_LOGGEDIN",
            payload: false,
          });
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClose = () => {
    setIsSignModal(false);
  };

  const handleJoyrideCallback = (data) => {
    console.log("handleJoyrideCallback", data.status);
    if (["finished", "skipped", "paused"].includes(data.status)) {
      setRun(false);
      setStepIndex(0);
    }
    if (
      data.action === "next" &&
      stepIndex === 2 &&
      data.controlled === false
    ) {
      setStepIndex(3);
    }
    if (data.action === "close" && data.controlled === true) {
      setRun(false);
    }
  };

  // const arr = {
  //   aboutUs: "aboutUs_001",
  //   about: "about_001",
  //   topNotification: "topNotification_001",
  //   ourServices: "ourServices_001",
  //   ourProjects: "ourProjects_001",
  //   ourClients: "ourClients_001",
  //   ourBrands: "ourBrands_001",
  //   ourGallery: "ourGallery_001",
  //   clientTestimonials: "clientTestimonials_001",
  //   videoGallery: "videoGallery_001",
  //   socialMedia: "socialMedia_001",
  //   externalLink: "externalLink_001",
  // };

  const handleCheckboxChange = (key, mode) => {
    // if (key === UPDATE_SECTION_SETTINGS) {
    //   dispatch({
    //     type: "UPDATE_ANIMATION_SECTION_SETTINGS",
    //     payload: {
    //       key: key,
    //       mode: mode,
    //     },
    //   });
    //   return;
    // }
    if (key === "onlineProduct") {
      dispatch({
        type: "UPDATE_ONLINE_STORE_SECTION_SETTINGS",
        payload: {
          key: key,
          mode: mode,
          templateId: "onlineProduct_001",
        },
      });
    }
    if (mode) {
      const templateData = postMessageData.pageProps[key];
      if (!templateData?.templateId && key !== "topNotification") {
        message.warning("Template is not selected. Please select the template");
      }
    }
    dispatch({
      type: UPDATE_SECTION_SETTINGS,
      payload: {
        key: key,
        mode: mode,
      },
    });
  };

  const handleFontChange = (key, value) => {
    dispatch({
      type: UPDATE_FONT_SETTINGS,
      payload: {
        key: key,
        value: value,
      },
    });
  };

  const iframeRefresh = () => {
    setIframeKey(uniqueId());
  };

  const handleAnimationChange = (value) => {
    if (value) {
      message.info("Please save to view changes in preview");
    }
    dispatch({
      type: UPDATE_ANIMATION_SETTINGS,
      payload: {
        value: value,
      },
    });
  };

  // dispatch({
  //   type: "UPDATE_ONLINE_STORE_SECTION_SETTINGS",
  //   payload: {
  //     key: key,
  //     mode: mode,
  //     templateId: "onlineProduct_001",
  //   },
  // });

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <Joyride
        continuous={false}
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlay={true}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        hideBackButton={true}
        hideCloseButton={true}
      />
      <Header
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#fff",
        }}
      >
        <Title style={{ color: "#EF4667", margin: "0" }} level={3}>
          Ghoshak
        </Title>
        <Space direction="horizontal">
          <div>
            <input
              onClick={handleWebsitePreview}
              type="radio"
              name="preview"
              id="desktop"
              defaultChecked
            />
            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor="desktop"
            >
              <IoLaptopOutline
                style={{
                  fontSize: "20px",
                }}
              />
            </label>
          </div>
          <div>
            <input
              onClick={handleTabletPreview}
              type="radio"
              name="preview"
              id="tablet"
            />
            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor="tablet"
            >
              <IoIosTabletPortrait
                style={{
                  fontSize: "20px",
                }}
              />
            </label>
          </div>
          <div>
            <input
              onClick={handleMobilePreview}
              type="radio"
              name="preview"
              id="mobile"
            />
            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor="mobile"
            >
              <ImMobile
                style={{
                  fontSize: "20px",
                }}
              />
            </label>
          </div>
        </Space>
        <div>
          <Button type="link" onClick={() => setRun(true)}>
            Help
          </Button>
          {shopDetails?.customDomainUrl && (
            <Button
              type="link"
              icon={<FaExternalLinkAlt />}
              onClick={() => {
                window.open(shopDetails?.customDomainUrl, "_blank");
              }}
            >
              Preview
            </Button>
          )}
          <ActionButton
            disabled={
              objectCompare(postMessageData, previousPostMessage) ? true : false
            }
            onClick={handleSaveClick}
            size="medium"
          >
            Save All Changes
          </ActionButton>
          <RiLogoutCircleRLine
            style={{
              fontSize: "2rem",
              marginLeft: "0.5rem",
              cursor: "pointer",
              verticalAlign: "middle",
            }}
            title="Sign Out"
            onClick={() => setIsSignModal(true)}
          />
        </div>
      </Header>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <div
            id="templates"
            style={{
              textAlign: "center",
              cursor: "pointer",
              height: "max-content",
            }}
            onClick={() => {
              setIsTemplateHide(false);
              iframeRefresh();
            }}
          >
            <Button
              style={{
                width: "80px",
                height: "80px",
                border: "none",
                borderRadius: "1rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TbTemplate
                style={{
                  fontSize: "2rem",
                }}
              />
            </Button>
            <p
              style={{
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                fontSize: 14,
              }}
            >
              Templates
            </p>
          </div>
          <div
            style={{
              textAlign: "center",

              cursor: "pointer",
              height: "max-content",
            }}
            onClick={() => setIsThemeHidden((prev) => !prev)}
          >
            <Button
              style={{
                width: "80px",
                height: "80px",
                border: "none",
                borderRadius: "1rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <HiColorSwatch
                style={{
                  fontSize: "2rem",
                }}
              />
            </Button>
            <p
              style={{
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                fontSize: 14,
              }}
            >
              Themes
            </p>
          </div>
          <div
            style={{
              textAlign: "center",

              cursor: "pointer",
              height: "max-content",
            }}
            onClick={() => setIsWebsiteSettingHidden((prev) => !prev)}
          >
            <Button
              style={{
                width: "80px",
                height: "80px",
                border: "none",
                borderRadius: "1rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <RiListSettingsFill
                style={{
                  fontSize: "2rem",
                }}
              />
            </Button>
            <p
              style={{
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                fontSize: 14,
              }}
            >
              Website Settings
            </p>
          </div>
          <div
            style={{
              textAlign: "center",

              cursor: "pointer",
              height: "max-content",
            }}
            onClick={() => setIsFontSettingsHidden((prev) => !prev)}
          >
            <Button
              style={{
                width: "80px",
                height: "80px",
                border: "none",
                borderRadius: "1rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <FaFont
                style={{
                  fontSize: "2rem",
                }}
              />
            </Button>
            <p
              style={{
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                fontSize: 14,
              }}
            >
              Typography Settings
            </p>
          </div>
        </div>
        <Sider
          width={350}
          style={{
            marginTop: "20px",
            padding: "1rem 0 1rem 0",
            borderRadius: "1rem",
            background: "#fff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            height: "72vh",
            overflowY: "auto",
          }}
          className="site-layout-background"
          hidden={isTemplateHide}
          title="Templates"
          ref={selectDesignRef}
        >
          <div
            style={{
              textAlign: "right",
            }}
          >
            <GrClose
              style={{
                marginRight: "1rem",
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsTemplateHide(true);
                setScrollToView("");
              }}
            />
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="dnd"
                >
                  {postMessageData.pageProps?.["topNotification"]?.active && (
                    <div
                      style={{
                        background: "#fff",
                        fontSize: 14,
                        paddingLeft: "1.5rem",
                      }}
                    >
                      <BiArrowFromLeft
                        style={{
                          marginLeft: 4,
                        }}
                      />
                      {"  "}
                      Top Notification
                    </div>
                  )}

                  {order.map((item, index) => {
                    return (
                      postMessageData.pageProps[item]?.active &&
                      item !== "contactUs" && (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Menu
                                id={`menuId${index}`}
                                onClick={(v) => handleClick(item, v)}
                                defaultSelectedKeys={["1"]}
                                mode="inline"
                                items={menuItem(
                                  item,
                                  postMessageData.pageProps[item]?.templateId
                                )}
                                style={
                                  previewImg?.[item] === undefined
                                    ? {
                                        padding: "0px 1.5rem",
                                        width: 350,
                                      }
                                    : {
                                        width: 350,
                                      }
                                }
                                className={clsx([classes.menu, "menu"])}
                                onOpenChange={() => handleScrollToView(item)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {Object.keys(otherTemplates())?.length > 0 && (
            <Divider>In Active sections</Divider>
          )}

          <Menu mode="vertical">
            {Object.keys(otherTemplates())?.map((item, index) => {
              return (
                <Menu.Item
                  key={item}
                  onClick={() => handleOtherTemplate(item)}
                  icon={<HomeOutlined />}
                >
                  {templatesName[item]}
                </Menu.Item>
              );
            })}
            {/* {postMessageData.pageProps["topNotification"]?.active !== true && (
              <Menu.Item icon={<HomeOutlined />}>Top Notification</Menu.Item>
            )} */}
          </Menu>
        </Sider>
        <Sider
          width={350}
          style={{
            height: "70vh",
            overflowY: "auto",
            marginTop: "20px",
            padding: "1rem 0 1rem 0",
            borderRadius: "1rem",
            background: "#fff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
          className="site-layout-background"
          hidden={isThemeHidden}
          title="Templates"
          ref={themeHiddenRef}
          id="themes"
        >
          <Menu
            onClick={handleThemeClick}
            mode="vertical"
            className={classes.themeMenu}
            selectedKeys={[`${postMessageData.pageProps?.colorTheme.id}`]}
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                padding: "1rem",
                position: "sticky",
                top: "-16px",
                left: "0",
                zIndex: 1,
                background: "#fff",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  marginBottom: "28px",
                }}
              >
                Selected palette:
              </h4>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "space-between",
                    fontSize: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "column",
                      fontSize: 12,
                    }}
                  >
                    <div>Primary</div>
                    <div
                      style={{
                        background: colorThemeValues.primaryColor,
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "inline-block",
                        border: "1px solid #000",
                      }}
                    ></div>
                    <label
                      htmlFor="primaryColor"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        textDecoration: "underline",
                        color: "#1990FF",
                        fontSize: "12px",
                      }}
                    >
                      Change Color
                    </label>
                    <input
                      id="primaryColor"
                      type={"color"}
                      name="primaryColor"
                      onChange={handleColorChange}
                      value={colorThemeValues.primaryColor}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <div>Secondary</div>
                    <div
                      style={{
                        background: colorThemeValues.backgroundColor,
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "inline-block",
                        border: "1px solid #000",
                      }}
                    ></div>
                    <label
                      htmlFor="bgColor"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#1990FF",
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Change Color
                    </label>
                    <input
                      id="bgColor"
                      type={"color"}
                      name="backgroundColor"
                      onChange={handleColorChange}
                      value={colorThemeValues.backgroundColor}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "column",
                      fontSize: "12px",
                    }}
                  >
                    <div>Text</div>
                    <div
                      style={{
                        background: colorThemeValues.accentColor1,
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "inline-block",
                        border: "1px solid #000",
                      }}
                    ></div>
                    <label
                      htmlFor="accentColor1"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#1990FF",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Change Color
                    </label>
                    <input
                      id="accentColor1"
                      type={"color"}
                      name="accentColor1"
                      value={colorThemeValues.accentColor1}
                      onChange={handleColorChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "column",
                      fontSize: "12px",
                    }}
                  >
                    <div>Button</div>
                    <div
                      style={{
                        background: colorThemeValues.actionColor,
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "inline-block",
                        border: "1px solid #000",
                      }}
                    ></div>
                    <label
                      htmlFor="actionColor"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#1990FF",
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Change Color
                    </label>
                    <input
                      id="actionColor"
                      name="actionColor"
                      onChange={handleColorChange}
                      type={"color"}
                      value={colorThemeValues.actionColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            {postMessageData.pageProps.colorPallet.map((palette, index) => {
              return (
                <Menu.Item
                  key={palette.id}
                  // onClick={() => handleColorPalette(palette)}
                >
                  <div>
                    <div>{palette.paletteName}</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          background: palette.backgroundColor,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "inline-block",
                          border: "1px solid #000",
                        }}
                      ></span>
                      <span
                        style={{
                          background: palette.primaryColor,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "inline-block",
                          border: "1px solid #000",
                        }}
                      ></span>
                      <span
                        style={{
                          background: palette.accentColor1,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "inline-block",
                          border: "1px solid #000",
                        }}
                      ></span>
                      <span
                        style={{
                          background: palette.actionColor,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "inline-block",
                          border: "1px solid #000",
                        }}
                      ></span>
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
            {postMessageData.pageProps["topNotification"]?.active !== true && (
              <Menu.Item icon={<HomeOutlined />}>Top Notification</Menu.Item>
            )}
          </Menu>
        </Sider>
        <Sider
          width={350}
          style={{
            height: "70vh",
            overflowY: "auto",
            marginTop: "20px",
            padding: "1rem 0 1rem 0",
            borderRadius: "1rem",
            background: "#fff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
          className="site-layout-background"
          hidden={isWebsiteSettingHidden}
          title="Templates"
          ref={websiteSettingsHiddenRef}
          id="themes"
        >
          <div
            style={{
              padding: "2rem 1rem",
            }}
          >
            <Checkbox
              style={{
                marginLeft: 8,
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["aboutUs"]?.active}
              onChange={(v) =>
                handleCheckboxChange("aboutUs", v.target.checked)
              }
            >
              <Typography.Title level={5}>Welcome Page</Typography.Title>
              <span>The first page your customers view about you business</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["about"]?.active}
              onChange={(v) => handleCheckboxChange("about", v.target.checked)}
            >
              <Typography.Title level={5}> About Us</Typography.Title>
              <span>The first page your customers view about you business</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["onlineProduct"]?.active}
              onChange={(v) =>
                handleCheckboxChange("onlineProduct", v.target.checked)
              }
            >
              <Typography.Title level={5}> Online Store</Typography.Title>
              <span>
                The "One - Stop" destination to shop all your products
              </span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["ourServices"]?.active}
              onChange={(v) =>
                handleCheckboxChange("ourServices", v.target.checked)
              }
            >
              <Typography.Title level={5}> Our Services</Typography.Title>
              <span>The “ What you do” and “How you do” of your business</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["ourProjects"]?.active}
              onChange={(v) =>
                handleCheckboxChange("ourProjects", v.target.checked)
              }
            >
              <Typography.Title level={5}> Projects</Typography.Title>
              <span>
                Feature your best works that boost customer confidence
              </span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["ourClients"]?.active}
              onChange={(v) =>
                handleCheckboxChange("ourClients", v.target.checked)
              }
            >
              <Typography.Title level={5}>Clients</Typography.Title>
              <span>Show whom you work with - your clients</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["ourBrands"]?.active}
              onChange={(v) =>
                handleCheckboxChange("ourBrands", v.target.checked)
              }
            >
              <Typography.Title level={5}>Brands</Typography.Title>
              <span>Showcase the brands you are associated with</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["ourGallery"]?.active}
              onChange={(v) =>
                handleCheckboxChange("ourGallery", v.target.checked)
              }
            >
              <Typography.Title level={5}>Gallery</Typography.Title>
              <span>Add photos and Videos that you want to showcase</span>
            </Checkbox>

            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["clientTestimonials"]?.active}
              onChange={(v) =>
                handleCheckboxChange("clientTestimonials", v.target.checked)
              }
            >
              <Typography.Title level={5}>Testimonials</Typography.Title>
              <span>Display the reviews, appreciation from your clients</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["videoGallery"]?.active}
              onChange={(v) =>
                handleCheckboxChange("videoGallery", v.target.checked)
              }
            >
              <Typography.Title level={5}>Video Gallery</Typography.Title>
              <span>
                You can publish videos about your business and projects
              </span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["socialMedia"]?.active}
              onChange={(v) =>
                handleCheckboxChange("socialMedia", v.target.checked)
              }
            >
              <Typography.Title level={5}> Social Media</Typography.Title>
              <span>You can showcase the popularity of your social media</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["topNotification"]?.active}
              onChange={(v) =>
                handleCheckboxChange("topNotification", v.target.checked)
              }
            >
              <Typography.Title level={5}>Top Notification</Typography.Title>
              <span> Top notification bar highlights your announcements</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["externalLink"]?.active}
              onChange={(v) =>
                handleCheckboxChange("externalLink", v.target.checked)
              }
            >
              <Typography.Title level={5}>External Link</Typography.Title>
              <span>Increase your website's credibility by adding links</span>
            </Checkbox>
            <Checkbox
              style={{
                marginBottom: 8,
              }}
              checked={postMessageData.pageProps["appStore"]?.active}
              onChange={(v) =>
                handleCheckboxChange("appStore", v.target.checked)
              }
            >
              <Typography.Title level={5}>App Store</Typography.Title>
              <span>Add links to your Play store App</span>
            </Checkbox>
          </div>
        </Sider>
        <Sider
          width={350}
          style={{
            height: "70vh",
            overflowY: "auto",
            marginTop: "20px",
            padding: "1rem 0 1rem 0",
            borderRadius: "1rem",
            background: "#fff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
          className="site-layout-background"
          hidden={isFontSettingsHidden}
          title="Templates"
          ref={fontSettingRef}
          id="themes"
        >
          <div
            style={{
              padding: "1rem",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title level={5}>Fonts</Typography.Title>
              <GrClose
                onClick={() => {
                  setIsFontSettingsHidden(true);
                }}
              />
            </div>
            {console.log(postMessageData.pageProps, "fontsettings111")}
            <Form.Item
              name="font"
              label="Heading Font"
              colon={false}
              labelCol={{ span: 24 }}
            >
              <Select
                defaultValue={
                  postMessageData.pageProps["fontSettings"]?.heading
                }
                showSearch
                loading={googleFontList.length === 0}
                style={{ width: "100%" }}
                onChange={(v) => {
                  handleFontChange("heading", v);
                }}
                placeholder="Select a font"
              >
                {googleFontList.map((font) => (
                  <Select.Option key={font} value={font.family}>
                    {font.family}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Body Font" name="font" labelCol={{ span: 24 }}>
              <Select
                defaultValue={postMessageData.pageProps["fontSettings"]?.body}
                showSearch
                loading={googleFontList.length === 0}
                style={{ width: "100%" }}
                onChange={(v) => {
                  handleFontChange("body", v);
                }}
                placeholder="Select a font"
              >
                {googleFontList.map((font) => (
                  <Select.Option key={font} value={font.family}>
                    {font.family}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Animation"
              name="animation"
              labelCol={{ span: 24 }}
              style={{
                marginBottom: "0",
              }}
            ></Form.Item>

            <Switch
              checked={postMessageData.pageProps["animationSettings"]?.active}
              onChange={handleAnimationChange}
            />
          </div>
        </Sider>
        <main
          style={{
            width: preview,
            transition: "all 0.5s ease",
            marginTop: "-16px",
            marginInline: "auto",
            textAlign: "center",
            transform: editTemplate
              ? preview === "90%"
                ? "translateX(-190px) scaleX(.6) scaleY(.6)"
                : "translateX(-190px) scaleX(.9) scaleY(.9)"
              : "scaleX(0.9) scaleY(.9)",
          }}
        >
          {previewLoader && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeContent: "center",
              }}
            >
              <Loader />
            </div>
          )}
          {console.log("postMessageData", postMessageData)}
          {!previewLoader && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
              className="builder"
            >
              <IframeComm
                attributes={attributes}
                postMessageData={{
                  ...postMessageData,
                  //scrollToView: scrollToView,
                }}
                targetOrigin={"https://hellowords.in/livedemo"}
                handleReceiveMessage={onReceiveMessage}
              />
              <div className="zoomButton">
                <Button
                  style={{
                    borderRadius: "50%",
                  }}
                  onClick={() => iframeZoom("zoom-in")}
                  size="large"
                  icon={<BiPlus size={24} />}
                />
                <Button
                  style={{
                    borderRadius: "50%",
                  }}
                  onClick={() => iframeZoom("zoom-out")}
                  size="large"
                  icon={<BiMinus size={24} />}
                />
              </div>
            </div>
          )}
        </main>
      </Layout>
      {editTemplate && (
        <DynamicModal
          setEditTemplate={setEditTemplate}
          templateName={editTemplate}
          ref={dynamicModalRef}
          getUserDetails={getUserDetails}
          setStepIndex={setStepIndex}
        />
      )}
      <Modal
        visible={isSignModal}
        onClose={handleClose}
        onCancel={handleClose}
        footer={false}
        centered
      >
        <h5
          style={{
            fontSize: "1.5rem",
          }}
        >
          {currentLoginType === "internal"
            ? "Are you sure you want to Go Back?"
            : "Are you sure you want to sign out?"}
        </h5>
        <div
          style={{
            marginTop: "1rem",
            textAlign: "right",
          }}
        >
          <Button
            type="danger"
            style={{
              width: "100px",
              backgroundColor: "#EF4667",
            }}
            onClick={handleSignOut}
          >
            Yes
          </Button>
          <Button
            onClick={() => setIsSignModal(false)}
            style={{ marginLeft: "2rem", width: "100px" }}
          >
            No
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default LayoutwithSidebar;
