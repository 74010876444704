// const config = {
//   ChatGPTApiKey: "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",
// };

//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
//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
// export default config;

//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

const stage = "dev";

const config = {
  dev: {
    crmApiGateway:
      "https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev",
    posApiGateway:
      "https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev",
  },
  prod: {
    crmApiGateway:
      "https://uvx5horwme.execute-api.ap-south-1.amazonaws.com/prod",
    posApiGateway:
      "https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod",
  },
};

export default config[stage];
